// src/App.js
import React, { useContext, useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Loginpage from './pages/loginpage/Loginpage';
import Registerpage from './pages/registerpage/Registerpage';
import Contactpage from './pages/contactpage/Contactpage';
import Sitelistingpage from './pages/sitelistingpage/Sitelistingpage';
import Notfoundpage from './pages/notfoundpage/Notfoundpage';
import Addsitepage from './pages/addsitepage/Addsitepage';
import Addcontentpage from './pages/addcontentpage/Addcontentpage';
import Dashboard from './pages/dashboard/Dashboard';
import Aboutpage from './pages/aboutpage/Aboutpage';
import { FormDataContextProvider } from './Contexts/FormDataContexts';
import { DebugLevelContextProvider } from './Contexts/DebugLevelContext';
import Loadinpage from './pages/loadingpage/Loadinpage';
import { LoginStateContextProvider } from './Contexts/LoadingStateContext';
import Copyright from './components/copyright/Copyright';
import MirrorListing from './pages/mirrorlinsting/MirrorLIsting';
import { GlobalContextProvider } from './Contexts/GlobalContext';
import { ESTABLISH_WEBSOCKET_CONNECTION } from './serverRequests/ServerRequests';
import Addmirrorpage from './pages/addmirrorpage/Addmirrorpage';
import Contentlistingpage from './pages/contentlistingpage/Contentlistingpage';
import UpdateContentpage from './pages/updatecontentpage/UpdateContentpage';

function App() {

    const [selectedOption, setSeletedOption] = useState('register')
    const [sideBar, setSidebar] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    // useEffect(()=>{
        // ESTABLISH_WEBSOCKET_CONNECTION()
    // },[])

    return (
        <>
        <LoginStateContextProvider>
            <FormDataContextProvider>
                {isLoading ?
                    <Loadinpage /> :
                    <>
                        <div className='mx-5 mt-5 mb-0 bg-white'>
                            <img src='logo.png' className='h-20 p-0 m-0 ' onClick={() => setSidebar(!sideBar)} />
                            <p className='mx-1'><a href='/'>MirrorLink</a></p>

                        </div>


                        <section className='flex justify-center align-middle'>

                            <div className=' w-11/12 md:w-1/3  md:border md:px-10 border-gray-300 rounded-xl' >
                                {/* navbar */}
                                <div className='bg-blue-900 text-white p-10 hidden '>
                                    {/* left side options */}
                                    {/* <div className='text-lg font-bold'>
                                    <p><a href='/'>MirrorLink</a></p>
                                </div> */}
                                    {/* right side options */}
                                    <div className=''>
                                        <p className={selectedOption == 'dashboard' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/contact'> Contact</a></p>
                                        <hr className='border-gray-500' />
                                        <p className={selectedOption == 'logout' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'} ><a href='/#/login'> Logout</a></p>
                                        <hr className='border-gray-500' />
                                        <p className={selectedOption == 'about' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/about'> About</a></p>
                                        <hr className='border-gray-500' />
                                        <p className={selectedOption == 'login' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/login'> Login</a></p>
                                        <hr className='border-gray-500' />
                                        <p className={selectedOption == 'register' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/register'> Register</a></p>
                                    </div>
                                </div>



                                {sideBar ? <div>
                                    <div className='bg-blue-900 text-white p-10 '>
                                        {/* left side options */}
                                        <div className='text-lg font-bold'>
                                            <p><a href='/'>MirrorLink</a></p>
                                        </div>
                                        {/* right side options */}
                                        <div className=''>
                                            <p className={selectedOption == 'login' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/login'> Login</a></p>
                                            <hr className='border-gray-500' />
                                            <p className={selectedOption == 'register' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'} ><a href='/#/register'> register</a></p>
                                            <hr className='border-gray-500' />
                                            <p className={selectedOption == 'contact' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/contact'> contact</a></p>
                                            <hr className='border-gray-500' />
                                            <p className={selectedOption == 'viewers' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/viewers'> viewers</a></p>
                                            <hr className='border-gray-500' />
                                            <p className={selectedOption == 'contents' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/contents'> Contents</a></p>
                                            <hr className='border-gray-500' />
                                            <p className={selectedOption == 'sites' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/sites'> Sites</a></p>
                                            <hr className='border-gray-500' />
                                            <p className={selectedOption == 'about' ? 'bg-gray-100 text-blue-900 rounded-md px-2' : 'my-2  hover:text-gray-200 cursor-pointer'}><a href='/#/about'> About</a></p>
                                        </div>
                                    </div>
                                </div> : <div></div>}
                                <GlobalContextProvider>
                                    <DebugLevelContextProvider>
                                        <Router>
                                            <Routes>
                                                <Route path="/login" element={<Loginpage />} />
                                                <Route path="/register" element={<Registerpage />} />
                                                <Route path="/contact" element={<Contactpage />} />
                                                <Route path="/sitelist" element={<Sitelistingpage />} />
                                                <Route path="/contentlist" element={<Contentlistingpage />} />
                                                <Route path="/mirrorlist" element={<MirrorListing />} />
                                                {/* <Route path="/notfound" component={Notfoundpage} /> */}
                                                <Route path="/addsite" element={<Addsitepage />} />
                                                <Route path="/addmirror" element={<Addmirrorpage />} />
                                                <Route path="/addcontent" element={<Addcontentpage />} />
                                                <Route path="/updatecontent" element={<UpdateContentpage />} />
                                                <Route path="/about" element={<Aboutpage />} />
                                                <Route path="/" element={<Loginpage />} />
                                                {/* 404 Not Found route */}
                                                <Route element={<Notfoundpage />} />
                                            </Routes>
                                        </Router>
                                    </DebugLevelContextProvider>
                                </GlobalContextProvider>
                                <Copyright />

                            </div>

                        </section>

                    </>
                }

            </FormDataContextProvider>
            </LoginStateContextProvider>
        </>
    );
}

export default App;