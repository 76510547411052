// Request URL s and other Main urls for the application to communicate with server


const IS_REMOTE_SERVER = true

let SERVER_URL;
let SERVER_WEBSOCKET_URL;
const SERVER_ROOT_REMOTE_URL = "https://mirrorlinkserver.developingkerala.com/"
const SERVER_ROOT_LOCAL_URL = "http://127.0.0.1:8000/"
// const CONTROLLER_HTTP_URL = "https://mirrorlinkserver.developingkerala.com/AdministratorLogin"
const CONTROLLER_WEBSOCKET_REMOTE_URL = "wss://mirrorlinkserver.developingkerala.com/ws/controller?"
const CONTROLLER_WEBSOCKET_LOCAL_URL = "ws://127.0.0.1:8000/controller?"

if (IS_REMOTE_SERVER) {
    SERVER_URL = SERVER_ROOT_REMOTE_URL
    SERVER_WEBSOCKET_URL = CONTROLLER_WEBSOCKET_REMOTE_URL
}
else {
    SERVER_URL = SERVER_ROOT_LOCAL_URL
    SERVER_WEBSOCKET_URL = CONTROLLER_WEBSOCKET_LOCAL_URL
}
let socket;

// call this funtion as a promise by passing only the username and password
// return true if login in
// save the ws key in cookie
function MAKE_LOGIN(username, password) {
    // You can use fetch or another method to send this data to your server
    const LoginRequestURL = SERVER_URL + 'AdministratorLogin'
    return new Promise((resolve, reject) => {
        try {
            fetch(LoginRequestURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            }).then(response => response.json())
                .then((data) => {
                    if (data.status_code == 401) {
                        reject(false)
                    }
                    else {
                        const key = data.ws_secret_key;
                        // save the mirror link websocket key in cookie
                        document.cookie = key
                        resolve(true)
                    }
                }).catch((error) => {
                    console.log(error)
                    reject(false)
                })
        }

        catch {
            reject(false)

        }
    })
}
function ESTABLISH_WEBSOCKET_CONNECTION() {
    //     Here are the possible values for readyState:

    // 0: CONNECTING – The connection is not yet open.
    // 1: OPEN – The connection is open and ready to communicate.
    // 2: CLOSING – The connection is in the process of closing.
    // 3: CLOSED – The connection is closed or could not be opened.
    return new Promise((resolve, reject) => {
        // get the websocket mirrorlink key from the brower cookie
        const key = document.cookie
        if (key) {


            socket = new WebSocket(SERVER_WEBSOCKET_URL + `key=${key}`);

            // Listen for the connection to be established
            socket.onopen = function () {
                console.log('MirrorLink Server Socket connected');
                resolve(true)
            };

            // Listen for messages from the server
            socket.onmessage = function (event) {
                const data = JSON.parse(event.data);
                //   console.log('Message from server:', data);
            };

            socket.onclose = (e) => {
                console.log('MirrorLink Server Socet DisConnected!', e)
                reject(false)
            }

        }
    })


}
function GET_AVAILABLE_SITES(mirror_name, content, content_title, site_name) {

    return new Promise((resolve, reject) => {
        const request_data = {
            "service": "GetMySites",
        }
        socket.send(JSON.stringify(request_data))
        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            resolve(data)
            //   console.log('Message from server:', data);
        };

    })


}
function GET_AVAILABLE_MIRRORS_ON_SITE(site_id) {

    return new Promise((resolve, reject) => {
        const request_data = {
            "service": "GetMyMirrors",
            "site_id": site_id
        }
        socket.send(JSON.stringify(request_data))
        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            resolve(data)
            //   console.log('Message from server:', data);
        };

    })
}
function GET_AVAILABLE_CONTENTS_ON_SITE(site_id, mirror_id) {

    return new Promise((resolve, reject) => {
        const request_data = {
            "service": "GetMyContents",
            "site_id": site_id,
            "mirror_id": mirror_id
        }
        socket.send(JSON.stringify(request_data))
        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            resolve(data)
            //   console.log('Message from server:', data);
        };

    })
}
function ADD_SITE_IN_ACCOUNT(sitename, site_description) {

    return new Promise((resolve, reject) => {
        const request_data = {
            "service": "AddSite",
            "site_name": sitename,
            "site_description": site_description
        }
        socket.send(JSON.stringify(request_data))
        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            resolve(data)
            console.log('Message from server:', data);
        };
    })
}
function ADD_MIRROR_IN_SITE(mirrorname, mirrordescription, username, password, site_id) {

    return new Promise((resolve, reject) => {
        const request_data = {
            "service": "AddMirror",
            "mirror_name": mirrorname,
            "mirror_description": mirrordescription,
            "username": username,
            "password": password,
            "site_id": site_id
        }
        socket.send(JSON.stringify(request_data))
        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            resolve(data)
            console.log('Message from server:', data);
        };
    })
}
function ADD_CONTENT_IN_MIRROR(content_title, content_description, mirror_id, site_id, content) {

    return new Promise((resolve, reject) => {
      
        // Read the file as binary data
        const reader = new FileReader();
        reader.onload = function (event) {
            const fileData = event.target.result; 
            const request_data = {
                "service": "AddContent",
                "content_title": content_title,
                "content_description": content_description,
                "site_id": site_id,
                "mirror_id": mirror_id,
                "content":fileData
            }
            socket.send(JSON.stringify(request_data))
            socket.onmessage = function (event) {
                const data = JSON.parse(event.data);
                resolve(data)
                console.log('Message from server:', data);
            };
        }

        reader.readAsDataURL(content);
       
    })
}

export {
    MAKE_LOGIN,
    ESTABLISH_WEBSOCKET_CONNECTION,
    GET_AVAILABLE_SITES,
    GET_AVAILABLE_MIRRORS_ON_SITE,
    ADD_SITE_IN_ACCOUNT,
    ADD_MIRROR_IN_SITE,
    GET_AVAILABLE_CONTENTS_ON_SITE,
    ADD_CONTENT_IN_MIRROR
}