import React from 'react'

export default function Listitem({title, description}) {
  return (
    <div className='border rounded-lg p-5 border-gray-400 m-2'>
        <p className='text-gray-700 text-sm'>{title}</p>
        <p className='text-gray-500 text-xs'>{description}</p>
    </div>
  )
}
