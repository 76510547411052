import React, { useContext, useState } from 'react'
import Inputtextbox from '../../components/inputtextbox/Inputtextbox'
import Labelwithdescription from '../../components/labelwithdescription/Labelwithdescription'
import Uploadbutton from '../../components/uploadbutton/Uploadbutton'
import Submitbutton from '../../components/submitbutton/Submitbutton'
import { FormDataContext } from '../../Contexts/FormDataContexts'
import { ADD_MIRROR_IN_SITE, ADD_SITE_IN_ACCOUNT } from '../../serverRequests/ServerRequests'
import { GlobalContext } from '../../Contexts/GlobalContext'



export default function Addmirrorpage() {

  const {DATA, SETDATA} = useContext(GlobalContext)
  const [name, setname] = useState('')
  const [description, setdescription] = useState('')
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')

  const HandleSubmit = (e)=>{
    e.preventDefault()
    ADD_MIRROR_IN_SITE(name, description, username, password, DATA.SELECTED_SITE_ID).then((res)=>{
      console.log(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  return (
    <form onSubmit={HandleSubmit}>
      <Inputtextbox label={'Mirror Name'} description={'Enter your site name to identify it in future'} placeholder={'example mirror'} updatedata={setname}/>
      <Inputtextbox label={'Mirror Decription'} description={'Description for your site'} placeholder={'example mirror'} updatedata={setdescription}/>
      <Inputtextbox label={'Mirror Username'} description={'Enter Mirror Username'} placeholder={'example mirror'} updatedata={setusername}/>
      <Inputtextbox label={'Mirror Password'} description={'Enter Mirror Password'} placeholder={'example mirror'} updatedata={setpassword}/>
     

      <div className='flex justify-end p-2' >
        <Submitbutton text="Add" />
      </div>
    </form>
  )
}
