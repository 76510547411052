import React, { useContext, useState } from 'react'
import Inputtextbox from '../../components/inputtextbox/Inputtextbox'
import Submitbutton from '../../components/submitbutton/Submitbutton'
import { FormDataContext } from '../../Contexts/FormDataContexts'
import { ESTABLISH_WEBSOCKET_CONNECTION, MAKE_LOGIN } from '../../serverRequests/ServerRequests'
import { useNavigate } from 'react-router-dom'
import { Navigate_To_Dashboard, Navigate_To_Sitelist } from '../../constants'
import { DebugLevelLoginContext } from '../../Contexts/DebugLevelContext'
import { LoadingStateContext } from '../../Contexts/LoadingStateContext'
import Loadinpage from '../loadingpage/Loadinpage'
import Labelwithdescription from '../../components/labelwithdescription/Labelwithdescription'

export default function Loginpage() {
  const { DEBUG_LEVEL, SET_DEBBUG_LEVEL } = useContext(DebugLevelLoginContext)
  const { isLoading, setIsLoading } = useContext(LoadingStateContext)
  const { formdata, setformdata } = useContext(FormDataContext)
  const [username, setusername] = useState(formdata.username)
  const [password, setpassword] = useState(formdata.password)
  const [message, setmessage] = useState('')

  const navigate = useNavigate()

  const HandleLogin = async (e) => {
    setformdata({...formdata, 'username':username, 'password':password})
    setIsLoading(true)
    e.preventDefault();
    // Assuming MAKE_LOGIN is an async function that handles login
    MAKE_LOGIN(username, password).then((data) => {

      if (DEBUG_LEVEL == 3) { console.log('login Successfull') }
      ESTABLISH_WEBSOCKET_CONNECTION().then((data) => {
        if (DEBUG_LEVEL == 3) { console.log('socket is ready to rock') }
        setIsLoading(false)
        navigate(Navigate_To_Sitelist)
      }).catch((error) => {
        setIsLoading(false)
        setmessage("Something Went Wrong, Check your Connection.")
      })
    })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        setmessage("Invalid Login Credentials")
      })

  };


  return (
    <>
      {isLoading ?
        <Loadinpage /> :
        <div className='flex w-full mt-20'>

          <div className=' w-full'>
            {/* <p className='text-2xl mx'>Login</p> */}
            <form onSubmit={HandleLogin}>
              <div className='text-left'>
                <Inputtextbox icon={'user.png'} label="Administrator Username" description="Kindly Enter Your Valid Administrator Username" placeholder="Enter username" updatedata={setusername} />

                <Inputtextbox icon={'lock.png'} label="Administrator Password" description="Enter your Secret Password." placeholder="Enter password" updatedata={setpassword} />
                <p className='text-red-600 mx-2'>{message}</p>



                <div className='flex justify-end p-2' >
                  <Submitbutton text="Login" />
                </div>
              </div>


              <input />
            </form>

            <div>
              <Labelwithdescription label={'Create Account'} description={'If you are new here, we will suggest you to create a new account'} />
            </div>
          </div>

        </div>
      }
    </>
  )
}
